/**
  * Every Layout: The Cluster
  * @source https://every-layout.dev/layouts/cluster/
  */
cluster-l {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
}
