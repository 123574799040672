@import url(../../../stylesheets/tools/media-queries.css);

.pictureContainer {
  height: 0;
  overflow: hidden;
  padding-top: 52.1%;
  position: relative;
  @apply bg-black;
}

.picture {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.galleryControl {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 2rem;
  @apply bg-gray-100;
}

.galleryButton {
  height: 100%;
  display: flex;
  align-items: center;
}

.galleryButton.isDisabled path {
  stroke: var(--gray-300);
}

.galleryButtonPrev {
  padding: 0 1.5rem 0 0.625rem;
}

.galleryButtonNext {
  padding: 0 0.625rem 0 1.5rem;
}

.pictureInfo {
  position: absolute;
  inset: 0;
  background: var(--black);
  opacity: 0.85;
  color: white;
  font-size: 1rem;
  align-items: flex-start;
  overflow: auto;
  justify-content: space-between;
}

.pictureInfo span {
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0.625rem 0 0.625rem 1.25rem;
}

.pictureInfo button {
  font-size: 1.25rem;
  padding: 1.25rem;
}

.btnOverImage {
  position: absolute;
  padding: 0.25rem 0.5rem;
  @apply bottom-3;
  @apply left-2 desktop:left-6;
  @apply bg-black;
  @apply border border-solid border-white rounded-2xl;
  @apply text-white;

  &:focus-visible {
    outline: 2px solid;
  }
}

@mixin desktop {
  .galleryButtonPrev,
  .galleryButtonNext {
    padding: 0 1.5rem;
  }

  .pictureInfo span {
    padding: 1.5rem 0 1.5rem 2rem;
  }

  .pictureInfo button {
    padding: 1.5rem 2rem;
  }
}

.swiperContainer {
  @apply bg-black;
}

.swiperContainer :global(.swiper-pagination-bullet-active) {
  background-color: var(--green-500);
}

.swiperContainer :global(.swiper-pagination-bullet) {
  bottom: -10px;
}

.swiperContainer :global(.swiper-pagination-bullet-lock) {
  display: none;
}
