/**
  * Every Layout: The Icon
  * @source https://every-layout.dev/layouts/icon/
  */
icon-l svg {
  block-size: 0.75em;
  block-size: 1cap;
  inline-size: 0.75em;
  inline-size: 1cap;
}
