@import url(../../../stylesheets/tools/media-queries.css);

.gripHandle {
  position: absolute;
  right: 20px;
  top: 50%;
  margin-top: -10px;
  color: var(--gray-300);
  z-index: 15;
}

.item {
  background: white;
  position: relative;
  border: 1px solid var(--gray-200);
  padding: 2px;
  margin-bottom: 30px;
  cursor: grab;
  padding-right: 40px;
  user-select: none;
  @apply p-2;

  @mixin desktop {
    margin-bottom: 15px;
  }

  &:hover {
    .gripHandle {
      color: var(--gray-700);
    }
  }

  &:active {
    cursor: grabbing;
    border-style: dashed;
    border-color: var(--gray-700);
  }
}
