/**
  * Every Layout: The Cover
  * @source https://every-layout.dev/layouts/cover/
  */
cover-l {
  display: flex;
  flex-direction: column;
  min-block-size: 100vh;
  padding: var(--space);
}
