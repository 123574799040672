/**
  * Every Layout: The Grid
  * @source https://every-layout.dev/layouts/grid/
  */
grid-l {
  display: grid;
  grid-gap: var(--space);
  align-content: start;
  grid-template-columns: 100%;
}
