@import url(../../../stylesheets/tools/media-queries.css);

.container {
  width: 100%;
  min-height: 70px;
  @apply bg-white;
}

.childrenContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  @apply py-4;
  @apply gap-4;

  @mixin desktop {
    flex-direction: row;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    max-width: 960px;
    justify-content: end;
  }
}

.sticky {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 20;
  box-shadow: 0 4px 8px rgb(0 0 0 / 8%);
  @apply border-t;
  border-style: solid;
  @apply border-gray-300;
  transition: all 0.2s ease-in;

  @mixin desktop {
    position: sticky;
    top: 0;
    @apply bottom-full;
    @apply border-t-0;
  }

  .childrenContainer {
    @apply px-205;
  }
}

.invisibleOnSmallScreens {
  @mixin mobile {
    opacity: 0;
    pointer-events: none;
  }
}

.removedFromLargeScreens {
  @mixin desktop {
    display: none;
  }
}
