@import url(../../stylesheets/tools/media-queries.css);

.paginationContainer {
  position: absolute;
  bottom: calc(100% + 1rem);
  right: 0;

  @mixin mobile {
    visibility: hidden;
  }
}

.noProjectsHint {
  @apply text-gray-700;
  @apply pr-1;
  font-style: italic;
}
