@import url(../../../stylesheets/tools/media-queries.css);

.container {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-around;
  gap: 1rem;

  @mixin mobile {
    &.grid {
      display: grid;
      grid-template-columns: 1fr 1fr;

      a {
        width: 3.5rem;
        height: 3.5rem;
      }
    }
  }

  :global(> .mobile-only-flex) {
    display: none;
    @mixin mobile {
      display: block;
    }
  }
}

.overlay {
  position: fixed;
  z-index: 999;
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-self: center;
  justify-content: center;
  top: 0;
  @apply bg-yellow-100;
  left: 0;
  right: 0;

  @mixin mobile {
    flex-direction: column;
    padding: 3rem;
  }

  @mixin desktop {
    height: 60px;
    border-bottom: 1px solid;
    @apply border-gray-300;
  }
}

/* Todo: Extract general transition style to ITCSS folder (tools?) when https://betterplace.atlassian.net/browse/DEV-343 is done. */
.overlayEnter {
  opacity: 0;
  transform: scale(0.9);
}

.overlayEnterActive {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 200ms, transform 200ms;
}

.overlayExit {
  opacity: 1;
}

.overlayExitActive {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 200ms, transform 200ms;
}
