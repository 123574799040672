@import url(../../../stylesheets/tools/media-queries.css);

.container {
  position: relative;
  @mixin mobile {
    width: 100%;
  }
}

.form {
  @mixin mobile {
    width: 100%;
  }
}

.eraseButton {
  font-family: var(--fa-style-family-classic);
  font-weight: 900;
  position: absolute;
  right: 60px;
  z-index: 100;
  color: var(--gray-900);
  cursor: pointer;
  top: 50%;
  transform: translateY(-50%);
}

.searchButton {
  font-family: var(--fa-style-family-classic);
  width: 50px;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  font-size: 18px;
  padding-left: 15px;

  @mixin desktop {
    line-height: 25.7167px;
  }

  @mixin mobile {
    line-height: 22.85px;
  }
}

.searchInput {
  display: inline;
  height: auto !important;
  padding-top: 10px;
  padding-bottom: 10px;

  @mixin desktop {
    font-size: 18px;
    width: 330px;
    line-height: 25.7167px;
  }

  @mixin mobile {
    font-size: 16px;
    width: calc(100% - 50px);
    line-height: 22.85px;
  }
}
