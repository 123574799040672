/**
  * Every Layout: The Center
  * @source https://every-layout.dev/layouts/center/
  */
center-l {
  display: block;
  box-sizing: content-box;
  margin-inline: auto;
  max-inline-size: var(--container-size);
}
