div.container {
  padding: 0;
  border: none;
  background: transparent;

  :global(.frc-content) {
    margin: 0;
  }

  :global(.frc-icon) {
    margin-top: 0;
    margin-left: 0;
  }

  :global(.frc-banner) {
    position: static;
  }
  
  &.invertedColors {
    :global(.frc-icon) {
      fill: white;
      stroke: white;
    }

    :global(.frc-text), :global(.frc-banner a), :global(.frc-banner b) {
      color: white;
    }
    
    :global(.frc-progress::-webkit-progress-value), :global(.frc-progress::-moz-progress-bar)  {
      background: #357c00;
    }
  }
}
