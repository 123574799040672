@import url(../../stylesheets/tools/media-queries.css);

.optionsContainer {
  display: flex;
  align-items: flex-start;
  @apply mt-2 mr-12;
  flex-flow: column wrap;

  @mixin desktop {
    flex-direction: row;
    align-items: center;
  }
}

.editButtonsContainer {
  display: flex;
  flex-wrap: wrap;
}

.notEditableContainer {
  white-space: nowrap;
  color: var(--gray-700);

  &:hover {
    color: var(--gray-700);
  }

  @mixin desktop {
    @apply mr-2;
  }
}

.duplicateButton {
  white-space: nowrap;

  @mixin desktop {
    @apply mr-2 p-0;
  }
}

.externalNeedsContainer {
  @mixin desktop {
    @apply mr-2;
  }
}

.deleteButton {
  color: var(--gray-700);

  &:hover {
    color: var(--gray-700);
  }
}

.notDeletableContainer {
  color: var(--gray-300);
}

.notDeletableHint {
  cursor: not-allowed;
  text-decoration-line: line-through;
  @apply mr-1;
}
