/**
  * Every Layout: The Sidebar
  * @source https://every-layout.dev/layouts/sidebar/
  */
sidebar-l {
  display: flex;
  flex-wrap: wrap;
}

sidebar-l > * {
  flex-grow: 1;
}
