@define-mixin desktop {
  @media screen and (width >= 451px) {
    @mixin-content;
  }
}

@define-mixin mobile {
  @media screen and (width <= 450px) {
    @mixin-content;
  }
}
