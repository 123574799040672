.adminBox {
  width: 100%;
  @apply border;
  @apply rounded;
  @apply border-teal-500;
}

.header {
  display: flex;
  align-items: center;
  justify-content: center;
  @apply bg-teal-500;
  @apply text-white;
  @apply h-9;
}

.content {
  text-align: center;
  @apply p-4;
}

.quickAccessBoxContent {
  text-align: center;
  @apply py-4;
}

.variantDark {
  @apply border-teal-700;
}

.variantDark .header {
  @apply bg-teal-700;
}

.shadow {
  box-shadow: 0 0.25rem 0.5rem rgb(0 0 0 / 20%);
  border: none;
  border-radius: 0.25rem;

  .header {
    border-radius: 0.25rem 0.25rem 0 0;
  }
}
