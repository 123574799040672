/**
  * Every Layout: The Stack
  * @source https://every-layout.dev/layouts/stack/
  */
stack-l {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: var(--space);
}
