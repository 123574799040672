/**
  * Every Layout: The Box
  * @source https://every-layout.dev/layouts/box/
  */
box-l {
  display: block;
  padding: var(--space);
  border-width: var(--border-thin);

  /* ↓ For high contrast mode */
  outline: var(--border-thin) solid transparent;
  outline-offset: calc(var(--border-thin) * -1);
}
