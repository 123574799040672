.container {
  display: flex;
}

.pictureContainer {
  display: flex;
  flex-shrink: 0;
}

.picture {
  display: flex;
  justify-content: center;
  align-items: center;
  @apply border border-solid border-gray-200;
}

.name {
  display: block;
  @apply font-bold;
}

.location {
  display: block;
  @apply text-gray-700;
}
