.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.projectsWirwunderHeader {
  background: #ff0000;
}

.projectsWirwunderHeader .logoWirwunder,
.projectsNussbaumHeader .logoGemeinsamHelfen {
  margin-top: 0.5rem;
  margin-bottom: 0.75rem;
}

@media only screen and (width <= 450px) {
  .projectsWirwunderHeader .logoWirwunder,
  .projectsNussbaumHeader .logoGemeinsamHelfen {
    margin-left: 10px;
    max-height: 40px;
  }
}

@media only screen and (width >= 451px) {
  .projectsWirwunderHeader .logoWirwunder {
    margin-left: 51px;
    height: 50px;
  }

  .projectsWirwunderHeader .logoSparkasse {
    margin-right: 26px;
    height: 70px;
  }
}

@media only screen and (width <= 450px) {
  .projectsWirwunderHeader .logoSparkasse,
  .projectsNussbaumHeader .logoNussbaum {
    height: 60px;
    max-width: 210px;
  }

  .projectsNussbaumHeader .logoNussbaum {
    margin-right: 10px;
  }
}

.projectsNussbaumHeader {
  background: #662465;
}

@media only screen and (width >= 451px) {
  .projectsNussbaumHeader .logoGemeinsamHelfen {
    margin-left: 51px;
    height: 140px;
    width: 254px;
  }

  .projectsNussbaumHeader .logoNussbaum {
    margin-right: 51px;
    height: 140px;
    width: 244px;
  }
}
