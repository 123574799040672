/**
  * Every Layout: The Switcher
  * @source https://every-layout.dev/layouts/switcher/
  */
switcher-l {
  display: flex;
  flex-wrap: wrap;
}

switcher-l > * {
  /*
   * https://heydonworks.com/article/the-flexbox-holy-albatross/
   * Switch from “extremely” negative to positive at threshold.
   */
  flex-basis: calc((var(--threshold) - 100%) * 999);
  flex-grow: 1;
}
