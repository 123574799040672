.box {
  @apply flex;
  @apply flex-col;
  @apply justify-center;
  @apply items-center;
  @apply mb-2;
  @apply bg-white;
  @apply border;
  @apply border-dashed;
  @apply border-gray-500;
  @apply outline-none;
  @apply cursor-pointer;
  @apply overflow-hidden;
  height: 150px;
}

.boxFullSize {
  @apply relative;
  @apply w-full h-0;
  padding-top: calc(52.05% - 2px);
}

.boxWithInlinePreview {
  @apply border-none;
  padding-top: 52.05%;
}

.boxWithFormError {
  @apply border-red-700;
}

.cta {
  @apply flex;
  @apply flex-col;
  @apply items-center;
  @apply w-full;
  @apply text-center;
}

.ctaForInlinePreview {
  @apply absolute;
  @apply top-1/2;
  @apply left-1/2;
  transform: translate(-50%, -50%);
}

.previewImage {
  @apply absolute;
  @apply top-0;
  @apply w-full h-full;
  @apply bg-cover bg-center;
  padding-top: 52.05%;
}

.icon {
  @apply mb-2;
  @apply text-gray-300;
}
