@import url(../../../stylesheets/tools/media-queries.css);

.picker {
  position: relative;
}

.swatch {
  width: 2.125rem;
  height: 2.125rem;
  cursor: pointer;
  border-radius: 4px;
}

.triggerSwatch {
  box-shadow: 0 0 0 1px var(--gray-300);
  border: 3px solid white;
}

.activePickerSwatch {
  border: 3px solid white;
  box-shadow: 0 0 0 1px rgb(53 124 0 / 80%), inset 0 1px 1px rgb(0 0 0 / 7.5%), 0 0 8px rgb(53 124 0 / 60%);
}

.popover {
  position: absolute;
  left: 0;
  border-radius: 4px;
  filter: drop-shadow(rgb(0 0 0 / 25%) 0 1px 4px);
  background-color: white;
  z-index: 3;
  margin-top: 0.75rem;
  transform: translateZ(0); /* Fixes Safari rendering issue (https://github.com/mdn/browser-compat-data/issues/17726) */

  &::before {
    content: '';
    position: absolute;
    transform: translateY(calc(-100% + 1px));
    left: calc(2.125rem / 4);
    width: 1rem;
    height: 0.5rem;
    clip-path: polygon(0% 100%, 50% 0%, 100% 100%);
    background-color: white;
  }
}

.pickerSwatches {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  padding: 1rem;
  gap: 0.5rem;

  @mixin desktop {
    grid-template-columns: repeat(7, 1fr);
  }
}

.input {
  max-width: 100%;
  grid-column: span 3;
}
