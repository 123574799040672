@import url(../../../stylesheets/tools/media-queries.css);

.container {
  font-weight: normal;
  width: 100%;
  @apply border border-solid border-gray-300 rounded;
  transition: 0.15s box-shadow ease-out;
  text-decoration: none;
  overflow: auto;

  &:hover,
  &:focus {
    text-decoration: none;
    color: inherit;
  }
}

.imageContainer {
  width: 100%;
  height: 0;
  padding-top: 52.1%;
  background-size: cover;
}

.placeholderImageContainer {
  @apply bg-purple-700;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 52.1%;
  position: relative;
}

.placeholderImage {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.contentContainer {
  @apply p-4;
}

.truncateBox {
  display: -webkit-box;
  -webkit-line-clamp: 5;
  line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
  @apply text-black;
}

.title {
  display: inline;
  @apply font-bold;
  @apply text-base;
}

.date {
  @apply text-gray-700;
}

.ownerInfoContainer {
  @apply text-gray-700;
  @apply p-4 pt-0;
}

.ownerInfoBox {
  @apply border-t border-solid border-gray-300;
  @apply pt-4;
}

@mixin desktop {
  .container:hover,
  .container:focus {
    box-shadow: 0 0 10px 0 rgb(0 0 0 / 50%);
  }

  .container:hover .title,
  .container:focus .title {
    text-decoration: underline;
  }
}
