/**
  * Every Layout: The Reel
  * @source https://every-layout.dev/layouts/reel/
  */
reel-l {
  display: flex;
  overflow: auto hidden;
  scrollbar-color: var(--color-light) var(--color-dark);
}

reel-l > * {
  flex: 0 0 var(--item-width);
}

reel-l > img {
  block-size: 100%;
  flex-basis: auto;
  inline-size: auto;
}

reel-l::-webkit-scrollbar {
  block-size: 1rem;
}

reel-l::-webkit-scrollbar-track {
  background-color: var(--color-dark);
}

reel-l::-webkit-scrollbar-thumb {
  background-color: var(--color-dark);
  background-image: linear-gradient(
    var(--color-dark) 0,
    var(--color-dark) 0.25rem,
    var(--color-light) 0.25rem,
    var(--color-light) 0.75rem,
    var(--color-dark) 0.75rem
  );
}
