.container {
  @apply p-4;
  @apply text-sm;
}

.variantInfo {
  @apply bg-green-100;
  @apply text-green-900;
  @apply border-green-500;
}

.variantSuccess {
  @apply bg-teal-500;
  @apply text-white;
}

.variantWarning {
  @apply bg-yellow-100;
}

.dashed {
  @apply border;
  border-style: dashed;
}

.centered {
  text-align: center;
}
