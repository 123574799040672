/**
  * Every Layout: The Imposter
  * @source https://every-layout.dev/layouts/imposter/
  */
imposter-l {
  position: absolute;
  inset-block-start: 50%;
  inset-inline-start: 50%;
  transform: translate(-50%, -50%);
}
