@import url(../../../stylesheets/tools/media-queries.css);

.container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  @apply gap-4;

  :global(> .btn) {
    flex: 1;
    white-space: nowrap;
  }

  @mixin desktop {
    width: auto;

    > * {
      flex: initial;
    }
  }
}

.wrappedContainer {
  flex-direction: column;
}
