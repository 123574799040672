/**
  * Every Layout: The Frame
  * @source https://every-layout.dev/layouts/frame/
  */
frame-l {
  aspect-ratio: 16 / 9;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

frame-l > img,
frame-l > video {
  inline-size: 100%;
  block-size: 100%;
  object-fit: cover;
}
